import classNames from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { isEmailValid as isEmailValidValidator, isPasswordValid } from '../../helpers/validators';
import AuthService, { setDefaultOrganizationRoleIfMissed } from '../../services/Auth.service';
import useAppStore from '../../store';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';
import CulturlabsLink from '../atoms/CulturlabsLink/CulturlabsLink';

const LoginPage = () => {
  const { loginForm, setLoginForm } = useAppStore(state => ({
    loginForm: state.loginForm,
    setLoginForm: state.setLoginForm,
  }));

  const navigate = useNavigate();
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isIdentifierFieldTouched, setIsIdentifierFieldTouched] = useState(false);
  const [errorMessages, setErrorMessages] = useState({ password: '' });

  useEffect(() => {
    if (isEmailValidValidator(loginForm.identifier?.trim())) {
      setIsEmailValid(true);
    }

    setErrorMessages({ password: '' });
  }, [loginForm.identifier]);

  useEffect(() => {
    setErrorMessages(prev => ({ ...prev, password: '' }));
  }, [loginForm.password]);

  const isPasswordValidMemo = useMemo(() => isPasswordValid(loginForm.password), [loginForm.password]);

  const resetLoginForm = () =>
    setLoginForm({
      identifier: '',
      password: '',
    });

  const validateIdentifierField = () => setIsEmailValid(isEmailValidValidator(loginForm.identifier?.trim()));

  const handleIdentifierInputBlur = () => {
    validateIdentifierField();
    setIsIdentifierFieldTouched(true);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    const { identifier, password } = loginForm;

    try {
      const { data } = await AuthService.authenticate({
        identifier: identifier.trim(),
        password,
      });
      const user = setDefaultOrganizationRoleIfMissed(data.user);
      AuthService.handleAuthResponse({ ...data, user });

      resetLoginForm();
      navigate('/index/survey');
    } catch (error) {
      setErrorMessages(prev => ({ ...prev, password: 'Incorrect password. Please try again.' }));
    }
  };

  const handleInputChange = (fieldName, event) => {
    setLoginForm({
      ...loginForm,
      [fieldName]: event.target.value ?? '',
    });
  };

  return (
    <form onSubmit={handleSubmit} noValidate>
      <h1 className="text-main-black text-xxl mb-[16px]">Login</h1>
      <CulturlabsInput
        label="email"
        required
        invalid={!isEmailValid && isIdentifierFieldTouched}
        handleChange={changeEvent => handleInputChange('identifier', changeEvent)}
        onBlur={handleIdentifierInputBlur}
      />
      <p
        className={classNames('text-red-validation-message text-m italic mb-[6px]', {
          invisible: isEmailValid || !isIdentifierFieldTouched,
        })}
      >
        Please enter a valid email address.
      </p>
      <CulturlabsInput
        label="password"
        inputType="password"
        required
        invalid={!!errorMessages.password}
        handleChange={changeEvent => handleInputChange('password', changeEvent)}
      />
      {errorMessages.password && (
        <p className="text-red-validation-message text-m italic mb-2">{errorMessages.password}</p>
      )}
      <CulturlabsLink
        className="block font-semibold mt-[8px] mb-[16px] text-s w-fit hover:underline"
        label="Forgot Password?"
        href="/forgot-password"
      />
      <CulturlabsButton
        disabled={!(isEmailValid && isPasswordValidMemo) || !!errorMessages.password}
        type="submit"
        className="mt-[24px] w-full"
      >
        Login
      </CulturlabsButton>
    </form>
  );
};

export default LoginPage;
