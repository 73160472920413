import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import checkmarkImage from '../../assets/images/checkmark.webp';
import emptyArrowFunc from '../../helpers/emptyArrowFunc.helper';
import unwrapValueFromInputChangeEvent from '../../helpers/unwrapValueFromInputChangeEvent.helper';
import { isEmailValid as isEmailValidValidator, NewPasswordValidation } from '../../helpers/validators';
import APIService from '../../services/API.service';
import useAppStore from '../../store';
import CulturlabsButton from '../atoms/CulturlabsButton/CulturlabsButton';
import CulturlabsInput from '../atoms/CulturlabsInput/CulturlabsInput';
import CulturlabsLink from '../atoms/CulturlabsLink/CulturlabsLink';

const ForgotPasswordPage = () => {
  const [forgotPasswordForm, setForgotPasswordForm] = useState({});
  const [searchParams] = useSearchParams();
  const [changePasswordToken, setChangePasswordToken] = useState(null);
  const [isNewPasswordSent, setIsNewPasswordSent] = useState(false);
  const isNewPasswordValidStore = useAppStore(state => state.isNewPasswordValid);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isEmailFieldTouched, setIsEmailFieldTouched] = useState(false);

  const validateEmailField = useCallback(
    () => setIsEmailValid(isEmailValidValidator(forgotPasswordForm.email?.trim())),
    [forgotPasswordForm.email],
  );

  useEffect(() => {
    if (isEmailFieldTouched) {
      validateEmailField();
    }
  }, [forgotPasswordForm.email, validateEmailField, isEmailFieldTouched]);

  useEffect(() => {
    if (!searchParams) {
      console.error('Code for reset password should be provided');
    } else {
      const changePasswordToken = searchParams.get('code');
      if (!changePasswordToken?.length) return;
      setChangePasswordToken(changePasswordToken);
    }
  }, []);

  const navigate = useNavigate();

  const onSubmit = async event => {
    event.preventDefault();
    if (!changePasswordToken?.length) {
      await onForgot();
    } else {
      await onReset();
    }
  };

  const onForgot = async () => {
    const response = await APIService.call({
      method: 'post',
      url: APIService.urls.forgotPassword,
      data: forgotPasswordForm,
    }).catch(emptyArrowFunc);

    if (response?.status === 200 && response?.data?.ok) {
      navigate('/reset-password');
    }
  };

  const onReset = async () => {
    const response = await APIService.call({
      method: 'post',
      url: APIService.urls.resetPassword,
      data: { ...forgotPasswordForm, code: changePasswordToken },
    }).catch(emptyArrowFunc);

    if (response?.status === 200) {
      console.log(response?.data);
      setIsNewPasswordSent(!isNewPasswordSent);
    }
  };

  const handleInputChange = (formControlName, originalEvent) => {
    const formControlUpdatedValue =
      originalEvent instanceof Object ? unwrapValueFromInputChangeEvent(originalEvent) : originalEvent;

    const formUpdate = {
      ...forgotPasswordForm,
      [formControlName]: formControlUpdatedValue || undefined,
    };
    setForgotPasswordForm(formUpdate);
  };

  const isPasswordMatches = Object.values(isNewPasswordValidStore).every(Boolean) && forgotPasswordForm.password;

  const isDisabledSubmit = () => {
    if (changePasswordToken?.length) {
      return isPasswordMatches ? false : true;
    }
    return forgotPasswordForm?.email ? false : true;
  };

  if (isNewPasswordSent) {
    return (
      <>
        <h1 className="text-main-black text-xxl text-center mt-[40%]">Success!</h1>

        <div className="w-full flex flex-col gap-[48px] mt-[48px]">
          <div className="flex flex-col gap-[48px] items-center">
            <img alt="checkmark" src={checkmarkImage} className="h-[100px]" />
            <p className="max-w-[400px] text-center">Your password has been successfully reset.</p>
          </div>
        </div>

        <CulturlabsButton
          type="button"
          className="mt-[48px] max-w-max mx-auto px-8 text-nowrap"
          onClick={() => navigate('/login')}
        >
          Return to Login
        </CulturlabsButton>
      </>
    );
  }

  return (
    <>
      <h1 className="text-main-black text-xxl mb-[16px]">
        {!changePasswordToken?.length ? 'Forgot password?' : 'Reset password'}
      </h1>
      {!changePasswordToken?.length && (
        <p className="font-inter text-l mb-[24px]">
          Let us help you. Enter your email below to receive a link to reset your password{' '}
        </p>
      )}
      <p className="font-inter text-l mb-[24px]">
        Back to <CulturlabsLink href="/login" className="text-l" label="Login" />
      </p>
      {!changePasswordToken?.length ? (
        <>
          <CulturlabsInput
            label="email"
            placeholderText="your@email.com"
            className="mt-[24px]"
            invalid={!isEmailValid}
            onBlur={() => setIsEmailFieldTouched(true)}
            handleChange={changeEvent => handleInputChange('email', changeEvent)}
          />
          <p
            className={classNames('text-red-validation-message text-m italic mb-[6px]', {
              invisible: isEmailValid,
            })}
          >
            Please enter a valid email address.
          </p>
        </>
      ) : (
        <>
          <CulturlabsInput
            inputType="password"
            label="New password"
            placeholderText="Enter new password"
            className="mt-[16px] mb-[16px]"
            handleChange={changeEvent => handleInputChange('password', changeEvent)}
          />

          <NewPasswordValidation
            password={forgotPasswordForm.password}
            passwordConfirmation={forgotPasswordForm.passwordConfirmation}
          />

          <CulturlabsInput
            inputType="password"
            label="Password confirmation"
            placeholderText="Enter password conformation"
            className="mt-[24px] mb-[48px]"
            handleChange={changeEvent => handleInputChange('passwordConfirmation', changeEvent)}
          />
        </>
      )}
      <CulturlabsButton
        disabled={isDisabledSubmit() || !isEmailValid}
        type="submit"
        className="mt-[24px] w-full"
        onClick={onSubmit}
      >
        Send Reset Link
      </CulturlabsButton>
    </>
  );
};

export default ForgotPasswordPage;
