import classNames from 'classnames';
import { useCallback, useEffect } from 'react';

import useAppStore from '../../store';

export const isPasswordValid = password => Boolean(password?.length >= 6);

export const NewPasswordValidation = ({ password, passwordConfirmation }) => {
  const isNewPasswordValidStore = useAppStore(state => state.isNewPasswordValid);
  const setIsNewPasswordValid = useAppStore(state => state.setIsNewPasswordValid);

  const validatePassword = useCallback(
    (password = '', confirmPassword = '') => {
      const validations = {
        length: password.length >= 12 && password.length <= 50,
        uppercase: /[A-Z]/.test(password),
        lowercase: /[a-z]/.test(password),
        number: /[0-9]/.test(password),
        specialChar: /[!@#$%^&*]/.test(password),
        passwordsMatch: password === confirmPassword,
      };

      setIsNewPasswordValid(validations);
    },
    [setIsNewPasswordValid],
  );

  useEffect(() => {
    validatePassword(password, passwordConfirmation);
  }, [password, passwordConfirmation, validatePassword, setIsNewPasswordValid]);

  return (
    <>
      <h6 className="text-main-black font-bold mb-[8px] text-s font-inter">Password requirements:</h6>

      <ul className="text-gray text-s pl-[8px]">
        <li>
          <span
            className={classNames('inline-block rounded-full h-[10px] w-[10px] bg-gray-invalid', {
              'bg-green-valid': isNewPasswordValidStore.length,
            })}
          />
          &nbsp; Between 12-50 characters long.
        </li>
        <li>
          <span
            className={classNames('inline-block rounded-full h-[10px] w-[10px] bg-gray-invalid', {
              'bg-green-valid': isNewPasswordValidStore.uppercase,
            })}
          />
          &nbsp; Contains at least one uppercase letter.
        </li>
        <li>
          <span
            className={classNames('inline-block rounded-full h-[10px] w-[10px] bg-gray-invalid', {
              'bg-green-valid': isNewPasswordValidStore.lowercase,
            })}
          />
          &nbsp; Contains at least one lowercase letter.
        </li>
        <li>
          <span
            className={classNames('inline-block rounded-full h-[10px] w-[10px] bg-gray-invalid', {
              'bg-green-valid': isNewPasswordValidStore.number,
            })}
          />
          &nbsp; Contains at least one number.
        </li>
        <li>
          <span
            className={classNames('inline-block rounded-full h-[10px] w-[10px] bg-gray-invalid', {
              'bg-green-valid': isNewPasswordValidStore.specialChar,
            })}
          />
          &nbsp; Contains at least one special character (!@#$%^&*).
        </li>
        <li>
          <span
            className={classNames('inline-block rounded-full h-[10px] w-[10px] bg-gray-invalid', {
              'bg-green-valid': isNewPasswordValidStore.passwordsMatch,
            })}
          />
          &nbsp; Passwords must match.
        </li>
      </ul>
    </>
  );
};
