import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

const CulturlabsInput = ({
  label,
  className,
  handleChange,
  initValue,
  disabled,
  placeholderText,
  inputType,
  required = false,
  invalid = false,
  ...props
}) => {
  const controlId = uuidv4();
  const [value, setValue] = useState(initValue);
  const [showPasswordState, setShowPassword] = useState(false);

  useEffect(() => {
    setValue(initValue);
  }, [initValue]);

  const handleInputChange = e => {
    setValue(e.target.value);
    if (handleChange) {
      handleChange(e);
    }
  };

  return (
    <div className={`flex flex-col relative ${className || ''} ${getClassesIfDisabled(disabled)}`}>
      <label htmlFor={controlId} className="text-main-black font-semibold mb-[8px] text-s uppercase font-inter">
        {label}
        {required && <span className="text-red text-[16px]">*</span>}
      </label>
      <input
        {...props}
        id={controlId}
        type={getInputType(inputType, showPasswordState)}
        disabled={disabled}
        value={value}
        className={classNames(
          'text-l font-inter placeholder:text-l rounded-[8px] outline-none border border-solid h-[48px] indent-[16px]',
          {
            'border-gray-01 hover:border-blue-01 focus:border-blue-01 focus:shadow-input': !invalid,
            'border-red-validation-message hover:border-red-validation-message focus:border-red-validation-message focus:shadow-input-invalid':
              invalid,
          },
        )}
        placeholder={placeholderText || 'Type text here'}
        onChange={handleInputChange}
      />
      {inputType === 'password' && (
        <FontAwesomeIcon
          className="absolute bottom-[16px] right-[12px] cursor-pointer"
          color={'#000000'}
          icon={showPasswordState ? faEye : faEyeSlash}
          onClick={() => setShowPassword(!showPasswordState)}
        />
      )}
    </div>
  );
};

export default CulturlabsInput;

const checkIfTypePassword = (inputType, showPasswordState) => inputType === 'password' && !showPasswordState;

const getInputType = (inputType, showPasswordState) =>
  checkIfTypePassword(inputType, showPasswordState) ? 'password' : 'text';

const getClassesIfDisabled = disabled => (disabled ? 'opacity-75' : '');
